import { useEffect, useState } from "react";

export const useCssVariable = (cssVariableString, defaultStateValue) => {
  const [cssVariableValue, setCssVariableValue] = useState(defaultStateValue);

  useEffect(() => {
    const style = getComputedStyle(window.document.body);
    setCssVariableValue(style.getPropertyValue(cssVariableString));
  }, []);

  return cssVariableValue;
};
