import { RELATED_PAGES_LABELS } from "components/constants";
import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import { HighSchool as HighSchoolModule } from "modules/high-school/HighSchool.component";
import React from "react";
import linksService from "services/links.service";

const HighSchoolPage = () => {
  useRelatedPages([
    { label: RELATED_PAGES_LABELS.ADMISSIONS, link: linksService.admissions() },
    {
      label: RELATED_PAGES_LABELS.COLLEGE_COUNSELING,
      link: linksService.collegeCounseling(),
    },
    {
      label: RELATED_PAGES_LABELS.HOW_TO_APPLY,
      link: linksService.howToApply(),
    },
    {
      label: RELATED_PAGES_LABELS.INQUIRY_FORM,
      link: linksService.inquiryForm(),
      newTab: true,
    },
  ]);

  return (
    <>
      <SEO title="High School" />
      <HighSchoolModule />
    </>
  );
};

export default HighSchoolPage;
