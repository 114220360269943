import { Link } from "components/link/Link.component";
import { CircleNote } from "components/page-specific-components/upcoming-events/circle-note/CircleNote.component";
import { SvgWrapper } from "components/svg-wrapper/SvgWrapper.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { useCssVariable } from "hooks/cssVariable.hook";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React from "react";
import linksService from "services/links.service";
import { getIcon } from "../../../../svg-wrapper/utils/get-icon/getIcon";

const topRow = [
  SVGIcons.Westmont,
  SVGIcons.Illinois,
  SVGIcons.NYU,
  SVGIcons.Gonzaga,
  SVGIcons.SeattlePacific,
  SVGIcons.UCLA,
  SVGIcons.NWU,
  SVGIcons.Baylor,
  SVGIcons.UNC,
  SVGIcons.York,
  SVGIcons.USC,
  SVGIcons.SMoC,
  // SVGIcons.HawaiiPacific,
  // SVGIcons.Boston,
];

const bottomRow = [
  SVGIcons.Emory,
  SVGIcons.UoSF,
  SVGIcons.CalPoly,
  SVGIcons.Berkeley,
  SVGIcons.Purdue,
  SVGIcons.UCI,
  SVGIcons.Clark,
  SVGIcons.LMU,
  // SVGIcons.UCSD,
  // SVGIcons.SantaClara,
];

const rows = [
  {
    universities: [
      ...topRow,
      //* REPEAT FOR ILLUSION THAT TRANSLATE NEVER ENDS
      ...topRow,
    ],
  },
  {
    universities: [
      ...bottomRow,
      //* REPEAT FOR ILLUSION THAT TRANSLATE NEVER ENDS
      ...bottomRow,
    ],
  },
];

export const PhotoBanner = () => {
  const windowWidth = useWindowWidth();
  const bannerHeight = useCssVariable("--bannerHeight", 0);

  const isMobile = windowWidth < 500;

  return (
    <div style={{ paddingTop: "1rem" }}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h3>Where are our graduates going?</h3>
            <h6 className="no-margin" style={{ padding: "10px 0px" }}>
              PacBay Graduates University Acceptances (2019-2022) [Partial
              List]:
            </h6>
          </div>
          {!isMobile && (
            <Link
              className="no-underline"
              to={linksService.collegeCounseling()}
            >
              <CircleNote
                note={
                  <span>
                    Are you a college looking to visit? <b>Click here!</b>
                  </span>
                }
                style={{
                  position: "relative",
                  rotate: "10deg",
                }}
                hasHoverAnimation
              />
            </Link>
          )}
        </div>
      </div>
      <section id="container">
        {rows.map((row) => (
          <div className="photo-banner-row-wrapper">
            <div className="photo-banner">
              {row.universities.map((iconType) => (
                <SvgWrapper iconType={iconType} height={bannerHeight} />
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};
