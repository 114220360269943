import { Chip } from "components/chip/Chip.component";
import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import {
  GET_MORE_INFO,
  QUESTION_MINIMIZE_BREAKPOINT,
} from "components/constants";
import { CoreValues } from "components/page-specific-components/schools/components/athletics/core-values/CoreValues.component";
import { Carousel } from "components/page-specific-components/schools/components/carousel/Carousel.component";
import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { SloganZigZag } from "components/page-specific-components/schools/components/slogan-zig-zag/SloganZigZag.component";
import { StaffCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.component";
import { staffCards as employeeCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.config";
import { sortStaffCards } from "components/page-specific-components/schools/components/staff-cards/StaffCards.helpers";
import { ChipRows } from "components/page-specific-components/schools/high-school/photo-banner/chip-rows/ChipRows.component";
import { PhotoBanner } from "components/page-specific-components/schools/high-school/photo-banner/PhotoBanner.component";
import { StackedCallToAction } from "components/stacked-call-to-action/StackedCallToAction.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowWidth } from "hooks/windowWidth.hook";
import baseball from "images/jpg/baseball.jpg";
import baseball1 from "images/jpg/baseball1.jpg";
import basketball1 from "images/jpg/basketball1.jpg";
import basketball2 from "images/jpg/basketball2.jpg";
import basketball3 from "images/jpg/basketball3.jpg";
import basketball4 from "images/jpg/basketball4.jpg";
import basketball5 from "images/jpg/basketball5.jpg";
import classroom from "images/jpg/classroom.jpg";
import soccer from "images/jpg/soccer.jpg";
import soccerSeniorNight from "images/jpg/soccer_senior_night.jpg";
import softball1 from "images/jpg/softball1.jpg";
import softball2 from "images/jpg/softball2.jpg";
import surfing from "images/jpg/surfing.jpg";
import volleyball from "images/jpg/volleyball2.jpg";
import volleyball2 from "images/jpg/volleyball3.jpg";
import volleyballSeniorNight from "images/jpg/volleyball_senior_night.jpg";
import workshop from "images/jpg/workshop.jpg";
import collegeFair from "images/png/college_fair.png";
import React, { useState } from "react";
import linksService from "services/links.service";
import "./HighSchool.styles.scss";

export const HighSchool = () => {
  const { highSchoolCards, administrationCards, staffCards } =
    sortStaffCards(employeeCards);
  const [seasonIndex, setSeasonIndex] = useState(0);
  const windowWidth = useWindowWidth();

  const middleSchoolSlogans = [
    {
      heading: "We provide a small college experience",
      points: [
        "Students choose from 100+ unique course options including 26 AP courses and 6 world languages.",
        "Students choose from 4 majors that align with their interests including philosophy, global studies, engineering, and design.",
      ],
      image: {
        src: workshop,
        alt: "two students doing arts and crafts",
      },
    },
    {
      heading: "We have well-rounded learning",
      points: [
        "Experiential & service learning",
        "STEM activities",
        "Academic engagement",
        "Competitive athletics",
        "Ecumenical spiritual formation",
        "Student leadership opportunities",
      ],
      image: {
        src: surfing,
        alt: "boy holding bow and arrows in front of archery targets",
      },
    },
    {
      heading: "We build connections for life",
      points: [
        "PacBay is committed to building a dynamic community that facilitates “connections for life.” Once a student is a PacBay eagle, we are committed to seeing each student soar and flourish, not only while in school, but also in college and beyond.",
      ],
      image: {
        src: classroom,
        alt: "students resting on mountain bikes laughing",
      },
    },
    {
      heading: "We are excellence-driven",
      points: [
        "Seniors meet with our college counselor every week and we host college representatives from many colleges throughout the year.",
      ],
      image: {
        src: collegeFair,
        alt: "students resting on mountain bikes laughing",
      },
    },
  ];

  const seasons = [
    {
      label: "Fall",
      images: [
        volleyball,
        volleyballSeniorNight,
        soccer,
        soccerSeniorNight,
        volleyball2,
      ],
      sports: [
        "Boys Soccer",
        "Boys and Girls Cross Country",
        "Girls Volleyball",
      ],
    },
    {
      label: "Winter",
      images: [basketball1, basketball3, basketball4, basketball2, basketball5],
      sports: ["Girls Basketball", "Boys Basketball"],
    },
    {
      label: "Spring",
      images: [baseball, softball2, baseball1, softball1],
      sports: ["Boys Baseball", "Girls Softball", "Track and Field"],
    },
  ];

  const chips = [
    "6 World Languages",
    "7:1 Student/Teacher Ratio",
    "7 Varsity Sports",
    "8 Outdoor Education Options",
    "10 Visual/Performing Arts Options",
    "26 AP Courses",
    "37 Acre Campus",
    "110 Courses Offered",
    "90 Electives Offered",
    "1 of 3 Teachers w/ Doctorate Degrees",
    "3 of 4 Teachers w/ Advanced Graduate Degrees",
    "4 Major Certificate Programs Available: Engineering, Philosophy, Design, Global Studies",
  ];

  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;
  return (
    <main>
      <WhiteGrouping>
        <section className="fix-header-overlap">
          <ImageHeading heading="High School">
            <StaticImage
              src="../../images/jpg/high_school.jpg"
              alt="two kids smiling and looking at each other"
              placeholder="blurred"
              layout="constrained"
              className="high-school-header-image-margining"
            />
          </ImageHeading>
          <div className="layout">
            <ChipRows Heading={() => <h3>By the numbers...</h3>}>
              {chips.map((chip) => (
                <Chip text={chip} />
              ))}
            </ChipRows>
          </div>
        </section>
        <SloganZigZag slogans={middleSchoolSlogans} />
        <section className="layout" style={{ paddingBottom: "10rem" }}>
          <PhotoBanner />
          <StackedCallToAction
            headerText={
              isMobile
                ? GET_MORE_INFO.HEADER.MOBILE
                : GET_MORE_INFO.HEADER.DESKTOP
            }
            paragraphText={
              isMobile
                ? GET_MORE_INFO.PARAGRAPH.MOBILE
                : GET_MORE_INFO.PARAGRAPH.DESKTOP
            }
            buttonObj={{
              label: GET_MORE_INFO.BUTTON_LABEL.DESKTOP,
              icon: SVGIcons.Document,
              link: linksService.inquiryForm(),
              newTab: true,
            }}
          />
        </section>
      </WhiteGrouping>
      <BlueGrouping>
        <section className="layout athletics-section">
          <div className="top-row">
            <CoreValues />
            <div className="carousel-wrapper">
              <Carousel
                seasons={seasons}
                seasonIndex={seasonIndex}
                setSeasonIndex={setSeasonIndex}
              />
            </div>
          </div>
          <div className="athletics-paragraphs">
            <h4>About Our High School Athletics</h4>
            <p>
              PacBay is a member school of the California Interscholastic
              Federation Central Coast Section. Our teams compete in the Private
              School Athletic League.
            </p>
            <p>
              We offer sideline cheer as well as a strength and conditioning
              club. Additionally, we have an athletic trainer onsite who
              supports our athletes.
            </p>
          </div>
        </section>
      </BlueGrouping>
      <WhiteGrouping>
        <section className="layout">
          <StaffCards heading="Faculty" cards={highSchoolCards} />
          <StaffCards heading="Administration" cards={administrationCards} />
          <StaffCards heading="Staff" cards={staffCards} />
        </section>
      </WhiteGrouping>
    </main>
  );
};
