import React, { useEffect } from "react";
import { Chip } from "components/chip/Chip.component";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const chipRowWrapperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const chipRowVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const h3Variants = {
  hidden: {
    opacity: 0,
    transform: "translateX(-5rem)",
  },
  visible: {
    opacity: 1,
    transform: "translateX(0rem)",
  },
};

export const ChipRows = ({ Heading, children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      <motion.div variants={h3Variants} initial="hidden" animate={controls}>
        <Heading />
      </motion.div>
      <motion.div
        ref={ref}
        variants={chipRowWrapperVariants}
        initial="hidden"
        animate={controls}
      >
        <motion.div variants={chipRowVariants} className="chips-container">
          {children}
        </motion.div>
      </motion.div>
    </>
  );
};
